import React from 'react';
import moment from 'moment';
import '../assets/components/OrderSummary/_ordersummary.css';

/************************************************/
/* Fix for onTouchStart not working with scroll */
/************************************************/
const handlePointerDown = (event) => {
	const startY = event.clientY || (event.touches && event.touches[0]?.clientY) || 0;
	const startX = event.clientX || (event.touches && event.touches[0]?.clientX) || 0;
	event.target.dataset.startY = startY;
	event.target.dataset.startX = startX;
};
  
const handlePointerUp = (event, handler) => {
	const startY = parseFloat(event.target.dataset.startY) || 0;
	const startX = parseFloat(event.target.dataset.startX) || 0;
	const endY = event.clientY || (event.changedTouches && event.changedTouches[0]?.clientY) || 0;
	const endX = event.clientX || (event.changedTouches && event.changedTouches[0]?.clientX) || 0;

	const moved = Math.abs(endY - startY) > 10 || Math.abs(endX - startX) > 10;

	//console.log("Moved",moved);
	if (!moved) {
		handler();
	}
};

const OrderSummary = ({ order, onClick }) => {
  return (
    <div className="order-summary" key={order.id}
		onTouchStart={handlePointerDown}
		onTouchEnd={(event) => handlePointerUp(event, () => onClick(order))}
	>
      <div className="order-header">
        {order?.display_name}
      </div>
	  <div className="pickup-time">Pickup Time:<br/>{order?.pickuptime ? (
		<>
		{moment(order.pickuptime).format('ddd M/D')}
		<br/>
		{moment(order.pickuptime).format('h:mm a')}
		</>
	  ) : ('')}</div>

	  {(order.type === 'DELIVERY' || order.source === 'DOORDASH') && (
        <div className="delivery-tag" key="delivery-tag">For Delivery</div>
      )}
      {order.note && (
        <div className="order-note" key="note">
          <span className="note">Note: {order.note}</span>
        </div>
      )}
      <div className="order-body" key="order-body">
        {order?.order_line_items.map((item, index) => (
			<div className="line-item" key={item.id}>
				<div className="name">
					{(Array.isArray(item?.order_line_item_modifiers)) && item.order_line_item_modifiers.map((modifier) => {
						//console.log("Before prefix includes", modifier);
						if (modifier.is_prefix === 1) {
							//console.log("It's included!", modifier);
							return (
								<span key={modifier.modifier_id}>
									{modifier.name}&nbsp;
								</span>
							);
						} else {
							return null; // If not included, return null to avoid rendering anything
						}
					})}
					{item?.variation?.item?.name} {(item?.variation?.item?.variations?.length > 1) && ` - ${item.variation.name}`}
				</div>
				{item.note && <div className="notes">Note: {item.note}</div>}
				<div className="modifiers">
				{Array.isArray(item?.order_line_item_modifiers) && item.order_line_item_modifiers.map((modifier, index) => {
					//console.log(modifier);
					if (modifier.is_prefix) {
					return null;
					} else if (modifier.highlighted && modifier.modifier_id === modifier.pre_selected) {
					return null;
					} else {
					return (
						<div key={modifier.modifier_id + '-' + index} className="modifier">
						{modifier.name}
						</div>
					)
				}
				})}
				</div>
			</div>
        ))}
      </div>
    </div>
  );
}

export default OrderSummary;
